// ----------------------------------------------------------------------

export const ROOTS = {
  AUTH: "/auth",
  ACCOUNT: "/account",
  DASHBOARD: "/dashboard",
};

// ----------------------------------------------------------------------

export const PAGES = {
  teammate: "https://uppl.it",
  page403: "/403",
  page404: "/not-found",
  page500: "/500",
  // AUTH
  auth: {
    login: () => ({
      title: "signin",
      path: `${ROOTS.AUTH}/login`,
    }),
    register: () => ({
      title: "signup",
      path: `${ROOTS.AUTH}/register`,
    }),
    forgotPassword: () => ({
      title: "forgot-password",
      path: `${ROOTS.AUTH}/forgot-password`,
    }),
    verifyEmail: () => ({
      title: "verify-email",
      path: `${ROOTS.AUTH}/verify-email`,
    }),
    verify: {
      title: "verify",
      path: `${ROOTS.AUTH}/verify`,
    },
    setup: {
      root: () => ({
        title: "setup",
        path: `${ROOTS.AUTH}/setup`,
      }),
      password: () => ({
        title: "setup-password",
        path: `${ROOTS.AUTH}/setup/password`,
      }),
      "2fa": {
        mode: {
          title: "setup-2fa-mode",
          path: `${ROOTS.AUTH}/setup/2fa/mode`,
        },
        connect: {
          title: "setup-2fa-connect",
          path: `${ROOTS.AUTH}/setup/2fa/connect`,
        },
      },
    },
  },
  account: () => ({
    root: () => ({
      name: "account",
      title: "account",
      path: `${ROOTS.ACCOUNT}`,
    }),
    notifications: () => ({
      name: "notifications",
      title: "notifications",
      path: `${ROOTS.ACCOUNT}/notifications`,
    }),
    security: () => ({
      name: "security",
      title: "security",
      path: `${ROOTS.ACCOUNT}/security`,
    }),
    appearance: () => ({
      name: "appearance",
      title: "appearance",
      path: `${ROOTS.ACCOUNT}/appearance`,
    }),
  }),
  // DASHBOARD
  dashboard: (organization_id = "") => ({
    root: () => ({
      title: "dashboard",
      path: `${ROOTS.DASHBOARD}/${organization_id}`,
    }),
    organization: () => ({
      root: () => ({
        title: "organization",
        path: `${ROOTS.DASHBOARD}/organization`,
      }),
      createOrJoin: () => ({
        title: "organization",
        path: `${ROOTS.DASHBOARD}/organization/createOrJoin`,
      }),
    }),
    help: () => ({
      root: () => ({
        title: "Help center",
        path: `${ROOTS.DASHBOARD}/${organization_id}/help`,
      }),
    }),
    teammates: () => ({
      root: () => ({
        title: "Teammates",
        path: `${ROOTS.DASHBOARD}/${organization_id}/teammates`,
      }),
      onboard: () => ({
        title: "Onboard",
        path: `${ROOTS.DASHBOARD}/${organization_id}/teammates/onboard`,
      }),
      details: (person_id = "") => ({
        title: "Coworker details",
        path: `${ROOTS.DASHBOARD}/${organization_id}/teammates/${person_id}`,
      }),
    }),
    integrations: () => ({
      root: () => ({
        title: "Integrations",
        path: `${ROOTS.DASHBOARD}/${organization_id}/integrations`,
      }),
      add: () => ({
        title: "Add",
        path: `${ROOTS.DASHBOARD}/${organization_id}/integrations/add`,
      }),
      app: (user_app_id: string) => ({
        title: "app",
        path: `${ROOTS.DASHBOARD}/${organization_id}/integrations/details/${user_app_id}`,
      }),
    }),
    teams: () => ({
      title: "Teams",
      path: `${ROOTS.DASHBOARD}/${organization_id}/teams`,
    }),

    settings: () => ({
      root: () => ({
        name: "organization_settings",
        title: "settings",
        path: `${ROOTS.DASHBOARD}/${organization_id}/settings`,
      }),
      members: () => ({
        name: "organization_members",
        title: "members",
        path: `${ROOTS.DASHBOARD}/${organization_id}/settings/members`,
      }),
      billing: () => ({
        name: "organization_billing",
        title: "billing",
        path: `${ROOTS.DASHBOARD}/${organization_id}/settings/billing`,
      }),
    }),
  }),
};
