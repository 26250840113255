import { ROOTS } from "./routes/site-paths";

export const MAPBOX_API = process.env.NEXT_PUBLIC_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = ROOTS.DASHBOARD; // as '/dashboard'

export interface PublicConfig {
  sentry: {
    dsn: string;
  };
  host: string;
  baseUrl: string;
  apiEndpoint: string;
  env: "local" | "preview" | "recette" | "production";
  version: string;
  novuAppId: string;
  stripePublishableKey: string;
}

function getProductionPublicConfig(): PublicConfig {
  const host = "app.uppl.it";

  return {
    sentry: {
      dsn: "https://712a21639080873d4f000dcb1be3adb6@sentry.uppl.it/3",
    },
    host,
    baseUrl: `https://${host}`,
    env: "production",
    apiEndpoint: `https://${host}/api`,
    version: getVersion(),
    novuAppId: "roR16bLduPSO",
    stripePublishableKey:
      "pk_test_51KQHb7J7mbTtNDNYzkoQBZt7OwgUN20TUFzbbnnM4uC7nOZ05DmFRU34APBJBE4IVz7KlyOtux159A0GzDzLbipr00E24itQlp",
  };
}

function getRecettePublicConfig(): PublicConfig {
  const host = "app-staging.uppl.it";

  return {
    sentry: {
      dsn: "https://712a21639080873d4f000dcb1be3adb6@sentry.uppl.it/3",
    },
    host,
    baseUrl: `https://${host}`,
    env: "recette",
    apiEndpoint: `https://${host}/api`,
    version: getVersion(),
    novuAppId: "Xivs9mOrbhOU",
    stripePublishableKey:
      "pk_test_51KQHb7J7mbTtNDNYzkoQBZt7OwgUN20TUFzbbnnM4uC7nOZ05DmFRU34APBJBE4IVz7KlyOtux159A0GzDzLbipr00E24itQlp",
  };
}

function getPreviewPublicConfig(): PublicConfig {
  const version = getVersion();
  const matches = version.match(/^0\.0\.0-(\d+)$/);

  if (!matches) {
    throw new Error(`getPreviewPublicConfig: invalid preview version ${version}`);
  }

  const host = `${matches[1]}.app-preview.uppl.it`;

  return {
    sentry: {
      dsn: "https://712a21639080873d4f000dcb1be3adb6@sentry.uppl.it/3",
    },
    host,
    baseUrl: `https://${host}`,
    env: "preview",
    apiEndpoint: `https://${host}/api`,
    version: getVersion(),
    novuAppId: "Xivs9mOrbhOU",
    stripePublishableKey:
      "pk_test_51KQHb7J7mbTtNDNYzkoQBZt7OwgUN20TUFzbbnnM4uC7nOZ05DmFRU34APBJBE4IVz7KlyOtux159A0GzDzLbipr00E24itQlp",
  };
}

function getLocalPublicConfig(): PublicConfig {
  const host = "localhost";
  return {
    sentry: {
      dsn: "https://712a21639080873d4f000dcb1be3adb6@sentry.uppl.it/3",
    },
    host,
    baseUrl: `http://${host}:3000`,
    env: "local",
    apiEndpoint: `http://${host}:${process.env.NEXT_PUBLIC_API_PORT ?? 5000}/api`,
    version: getVersion(),
    novuAppId: "Xivs9mOrbhOU",
    stripePublishableKey:
      "pk_test_51KQHb7J7mbTtNDNYzkoQBZt7OwgUN20TUFzbbnnM4uC7nOZ05DmFRU34APBJBE4IVz7KlyOtux159A0GzDzLbipr00E24itQlp",
  };
}

function getVersion(): string {
  const version = process.env.NEXT_PUBLIC_VERSION;

  if (!version) {
    throw new Error("missing NEXT_PUBLIC_VERSION env-vars");
  }

  return version;
}

function getEnv(): PublicConfig["env"] {
  const env = process.env.NEXT_PUBLIC_ENV as string;
  switch (env) {
    case "production":
    case "recette":
    case "preview":
    case "local":
      return env;
    default:
      throw new Error(`Invalid NEXT_PUBLIC_ENV env-vars ${env}`);
  }
}

function getPublicConfig(): PublicConfig {
  switch (getEnv()) {
    case "production":
      return getProductionPublicConfig();
    case "recette":
      return getRecettePublicConfig();
    case "preview":
      return getPreviewPublicConfig();
    case "local":
      return getLocalPublicConfig();
  }
}

export const publicConfig: PublicConfig = getPublicConfig();
